import React, { useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container, RadioImage, Image } from './styles';

interface Image {
  id: string;
  url: string;
}

interface RadioImageGroupProps {
  images: Image[];
  selectedId?: string;
  size?: number;
  rounded?: boolean;
  onChange(selectedImageId: string): void;
}

const RadioImageGroup: React.FC<RadioImageGroupProps> = ({
  images,
  selectedId,
  size,
  rounded,
  onChange,
}) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (!selected) setSelected(selectedId ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container size={size ?? 128}>
      {images.map(image => {
        return (
          <RadioImage htmlFor={`radio-${image.id}`} key={image.id}>
            <Image
              imageUrl={image.url}
              size={size ?? 128}
              checked={selected === image.id}
              rounded={rounded ?? false}
            >
              {selected === image.id && <FiCheck />}
            </Image>
            <input
              id={`radio-${image.id}`}
              type="radio"
              value={image.id}
              name="group1"
              checked={selected === `radio-${image.id}`}
              onChange={() => {
                setSelected(image.id);
                onChange(image.id);
              }}
            />
          </RadioImage>
        );
      })}
    </Container>
  );
};

export default RadioImageGroup;
