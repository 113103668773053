import React from 'react';
import { Switch } from 'react-router-dom';
import About from '../pages/About';
import BasicInfo from '../pages/BasicInfo';
import ContactInfo from '../pages/ContactInfo';
import ForgotPassword from '../pages/ForgotPassword';
import Images from '../pages/Images';
import Items from '../pages/Items';
import Publish from '../pages/Publish';
import SignIn from '../pages/SignIn';
import SocialMedia from '../pages/SocialMedia';
import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/recuperar-senha" exact component={ForgotPassword} />
    <Route path="/informacoes-basicas" component={BasicInfo} isPrivate />
    <Route path="/redes-sociais" component={SocialMedia} isPrivate />
    <Route path="/sobre" component={About} isPrivate />
    <Route path="/imagens" component={Images} isPrivate />
    <Route path="/items" component={Items} isPrivate />
    <Route path="/contato" component={ContactInfo} isPrivate />
    <Route path="/publicacao" component={Publish} isPrivate />
  </Switch>
);

export default Routes;
