import jwtDecode from 'jwt-decode';
import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

interface Customer {
  id: string;
  name: string;
}

interface Site {
  id: string;
  domain: string;
  businessName: string;
  title: string;
  whatsappNumber: string;
  businessAddress: string;
  about: string;
  itemsType: string;
  contactText: string;
  published: boolean;
}
interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  customer: Customer;
  site: Site;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateSite(site: Site): void;
}

interface AuthState {
  token: string;
  customer: Customer;
  site: Site;
}

interface JwtObject {
  exp: number;
  iat: number;
  sub: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@OxyBuilder:token');
    const customer = localStorage.getItem('@OxyBuilder:customer');
    const site = localStorage.getItem('@OxyBuilder:site');

    if (token && customer && site) {
      const decodedToken = jwtDecode(token) as JwtObject;
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem('@OxyBuilder:token');
        localStorage.removeItem('@OxyBuilder:customer');
        localStorage.removeItem('@OxyBuilder:site');
        return {} as AuthState;
      }
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, customer: JSON.parse(customer), site: JSON.parse(site) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessions', { email, password });

    const { token, customer, site } = response.data;

    localStorage.setItem('@OxyBuilder:token', token);
    localStorage.setItem('@OxyBuilder:customer', JSON.stringify(customer));
    localStorage.setItem('@OxyBuilder:site', JSON.stringify(site));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, customer, site });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@OxyBuilder:token');
    localStorage.removeItem('@OxyBuilder:customer');
    localStorage.removeItem('@OxyBuilder:site');
    setData({} as AuthState);
  }, []);

  const updateSite = useCallback(
    (site: Site) => {
      localStorage.setItem('@OxyBuilder:site', JSON.stringify(site));

      setData({
        token: data.token,
        customer: data.customer,
        site,
      });
    },
    [setData, data.token, data.customer],
  );

  return (
    <AuthContext.Provider
      value={{
        customer: data.customer,
        site: data.site,
        signIn,
        signOut,
        updateSite,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
