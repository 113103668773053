import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import ButtonNext from '../../components/ButtonNext';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import ButtonsPane from '../../components/ButtonsPane';
import { Radio, RadioGroup } from './styles';

interface BasicInfoFormData {
  title: string;
  description: string;
}

const BasicInfo: React.FC = () => {
  const [checkedValue, setCheckedValue] = useState('');

  const { site, updateSite } = useAuth();
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    setCheckedValue(site.itemsType);
  }, []);

  const { addToast } = useToast();

  // const siteData = await api.get(`/sites/${customer.siteId}`);

  const handleSubmit = useCallback(
    async (data: BasicInfoFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required(
            'É obrigatório inserir um título para o site',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const patchData = {
          id: site.id,
          businessName: data.title,
          title: data.description,
          itemsType: checkedValue,
        };

        const response = await api.patch('/sites/basic-information', patchData);

        updateSite(response.data);

        addToast({
          type: 'success',
          title: 'Informações básicas salvas com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao salvar as informações',
            description:
              'Tente novamente mais tarde . Se o erro persistir, contate o suporte.',
          });
        }
      }
    },
    [addToast, site.id, updateSite, checkedValue],
  );

  return (
    <PageStructure menuActive={0}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          title: site.businessName,
          description: site.title,
          itemsType: site.itemsType,
        }}
      >
        <h3>
          Para iniciar, necessitamos de algumas informações básicas. Preencha os
          campos a seguir e clique em
          <strong> Salvar e prosseguir</strong>
        </h3>

        <Input name="title" placeholder="Título do site">
          Nome ou título de sua empresa ou negócio:
        </Input>

        <Input name="description" placeholder="Descrição">
          Digite uma frase que descreva o seu negócio:
        </Input>

        <h3>O que você vai vender?</h3>
        <RadioGroup>
          <Radio htmlFor="radio1" checked={checkedValue === 'products'}>
            <div>Produtos</div>
            <input
              id="radio1"
              type="radio"
              value="products"
              name="group1"
              checked={checkedValue === 'products'}
              onChange={() => setCheckedValue('products')}
            />
          </Radio>
          <Radio htmlFor="radio2" checked={checkedValue === 'services'}>
            <div>Serviços</div>
            <input
              id="radio2"
              type="radio"
              value="services"
              name="group1"
              checked={checkedValue === 'services'}
              onChange={() => setCheckedValue('services')}
            />
          </Radio>
        </RadioGroup>

        <ButtonsPane>
          <ButtonNext>Salvar</ButtonNext>
        </ButtonsPane>
      </Form>
    </PageStructure>
  );
};

export default BasicInfo;
