import React from 'react';
import { FiPower } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import Footer from '../Footer';
import Menu from '../Menu';

import {
  Container,
  Content,
  Header,
  HeaderContent,
  ContentForm,
} from './styles';
import logoImg from '../../assets/logo.svg';

interface PageStructureProps {
  menuActive?: number;
}

const PageStructure: React.FC<PageStructureProps> = ({
  children,
  menuActive,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}) => {
  const { signOut, customer, site } = useAuth();

  return (
    <Container>
      <Header>
        <HeaderContent>
          <div>
            <img src={logoImg} alt="Oxyhost" />
          </div>
          <div>
            <span>Bem-vindo(a),</span>
            <strong>{customer.name}</strong>
          </div>
          <div>
            <span>Site:</span>
            <strong>{site.domain}</strong>
          </div>
          <button type="button" onClick={signOut}>
            <FiPower />
          </button>
        </HeaderContent>
      </Header>
      <Content>
        <Menu menuActive={menuActive} />
        <ContentForm>{children}</ContentForm>
      </Content>
      <Footer />
    </Container>
  );
};

export default PageStructure;
