import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #27ae60;
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  min-width: 150px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;

  &:only-child {
    margin-left: auto;
  }

  &:hover {
    background: ${shade(0.2, '#27AE60')};
  }

  svg {
    margin-left: 10px;
  }
`;
