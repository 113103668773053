import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ImageProps {
  imageUrl: string;
}

export const Container = styled.div``;

export const ImagesList = styled.div`
  margin-top: 32px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(128px, 142px));
  justify-content: start;
`;

export const ButtonContainer = styled.div`
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;

  label {
    width: 48px;
    height: 48px;
    background: #ff9000;
    border: 0;
    border-radius: 50%;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    svg {
      color: #fff;
      align-self: center;
    }

    input {
      display: none;
    }

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  button {
    position: absolute;
    top: -8px;
    right: 5px;
    border: 0;
    background: #e53935;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    color: #fff;

    &:hover {
      background: ${shade(0.2, '#e53935')};
    }
  }
`;

export const Image = styled.div<ImageProps>`
  border: 1px solid #eee;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 128px;
  width: 128px;
  border-radius: 6px;

  ${imageProps => {
    return css`
      background-image: url(${imageProps.imageUrl});
    `;
  }}
`;
