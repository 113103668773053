import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCamera } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Logo, LogoPreview, LogoContainer, AlertPane } from './styles';
import ButtonNext from '../../components/ButtonNext';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import TextArea from '../../components/TextArea';
import ButtonsPane from '../../components/ButtonsPane';

import noImage from '../../assets/no-image.png';
import RadioImageGroup from '../../components/RadioImageGroup';
import CheckImageGroup from '../../components/CheckImageGroup';

interface AboutFormData {
  about: string;
}

interface Image {
  id: string;
  url: string;
  imageId: string;
  imageType: 'about' | 'logo' | 'banner';
}

const About: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState<string>('');
  const [selectedAboutImages, setSelectedAboutImages] = useState<string[]>([]);
  const [aboutImages, setAboutImages] = useState<Image[]>([]);
  const { site, updateSite } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const [logoUrl, setLogoUrl] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    api.get(`/siteimages/site/${site.id}/logo`).then(response => {
      if (response.data[0]) {
        const { url, imageId } = response.data[0];
        if (url) {
          setLogoUrl(url);
          setSelectedLogo(imageId);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api.get(`/siteimages/site/${site.id}/about`).then(response => {
      const responseImages = response.data as Image[];
      setAboutImages(responseImages);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const array = aboutImages.map(image => {
      return image.imageId;
    });
    setSelectedAboutImages(array);
  }, [aboutImages]);

  const { addToast } = useToast();

  const [images, setImages] = useState<Image[]>([]);

  const handleSubmit = useCallback(
    async (data: AboutFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          about: Yup.string().required(
            'Você deve escrever um texto descritivo sobre seu negócio. Capriche!',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const payload = {
          id: site.id,
          about: data.about,
        };

        const response = await api.patch('/sites/about', payload);

        updateSite(response.data);

        selectedAboutImages.forEach(async imageId => {
          if (!aboutImages.some(e => e.imageId === imageId)) {
            const payload2 = {
              siteId: site.id,
              imageId,
              imageType: 'about',
            };
            await api.post('/siteimages', payload2);
          }
        });

        let aboutImagesAux = aboutImages;
        aboutImages.forEach(async image => {
          if (!selectedAboutImages.some(e => e === image.imageId)) {
            await api.delete(`/siteimages/${image.id}`);
            aboutImagesAux = aboutImagesAux.filter(
              e => e.imageId !== image.imageId,
            );
          }
        });

        setAboutImages(aboutImagesAux);

        addToast({
          type: 'success',
          title: 'Informações básicas salvas com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao salvar as informações',
            description:
              'Tente novamente mais tarde . Se o erro persistir, contate o suporte.',
          });
        }
      }
    },
    [addToast, site.id, updateSite, selectedAboutImages, aboutImages],
  );

  useEffect(() => {
    api.get(`images/site/${site.id}`).then(response => {
      setImages(response.data);
    });
  }, [site.id]);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickConfirm = () => {
    setOpen(false);
    const payload = {
      siteId: site.id,
      imageId: selectedLogo,
      imageType: 'logo',
    };
    api
      .post('/siteimages', payload)
      .then(response => {
        setLogoUrl(response.data.url);
        addToast({
          title: 'Logotipo atualizado',
          type: 'success',
        });
      })
      .catch(() => {
        addToast({
          title: 'Erro ao atualizar logotipo',
          type: 'error',
          description:
            'Tente novamente mais tarde. Se o erro persistir, contate o administrador.',
        });
      });
  };

  const handleSelectAboutImages = useCallback(
    selectedImageIds => {
      // console.log(selectedAboutImages);
      if (selectedAboutImages.length > 6) {
        addToast({
          title: 'Você já possui 6 imagens selecionadas',
          type: 'error',
          description:
            'Desmarque uma das imagens selecionadas e tente novamente.',
        });
        const newArray = selectedAboutImages.splice(-1, 1);
        setSelectedAboutImages(newArray);
      } else {
        setSelectedAboutImages(selectedImageIds);
      }
    },
    [selectedAboutImages, addToast],
  );

  const handleChangeLogo = useCallback(
    selectedImageId => {
      setSelectedLogo(selectedImageId);
    },
    [setSelectedLogo],
  );

  return (
    <PageStructure menuActive={3}>
      <h3>
        Nesta página você irá preencher informações mais detalhadas sobre o seu
        negócio. Preencha os campos a seguir e clique em
        <strong> Salvar e prosseguir</strong>
      </h3>
      <h3>
        Aqui você poderá escolher uma de suas imagens da galeria para aparecer
        como logotipo de seu negócio em seu site. Clique no botão junto à imagem
        para alterar sempre que quiser. É necessário que a imagem tenha sido
        previamente enviada ou importada em sua galeria.
      </h3>

      {images.length === 0 ? (
        <AlertPane>
          <Alert severity="warning">
            Você ainda não possui nenhuma imagem em sua galeria. Volte no passo
            anterior e faça a importação de alguma imagem para que possa
            selecioná-la como logotipo de seu site.
          </Alert>
        </AlertPane>
      ) : (
        <LogoPreview>
          <LogoContainer>
            <Logo imageUrl={logoUrl || noImage} />
            <button
              type="button"
              title="Selecionar outro logotipo"
              onClick={handleClickOpen}
            >
              <FiCamera />
            </button>
          </LogoContainer>
        </LogoPreview>
      )}

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ about: site.about }}
      >
        <TextArea
          rows={6}
          name="about"
          placeholder="Exemplo: Estamos situados na zona mais nobre a cidade de
            Florianópolis e executamos nossos serviços com maestria desde 1976.
            Nossos serviços consistem em..."
        >
          Insira aqui um texto que descreva sua empresa ou negócio. Não há
          limites de caracteres ou parágrafos. Portanto, faça um texto completo
          e convicente para chamar a atenção de seus clientes na segunda área de
          seu site, a área SOBRE.
        </TextArea>

        <h3>
          Selecione até 6 imagens de sua galeria para serem exibidas na área
          SOBRE de seu site. Esse é um passo opcional. Se você desejar que
          nenhuma imagem seja exibida nessa área, não selecione nenhuma imagem
          aqui.
        </h3>

        {images.length === 0 ? (
          <AlertPane>
            <Alert severity="warning">
              Você ainda não possui nenhuma imagem em sua galeria. Volte no
              passo anterior e faça a importação de alguma imagem para que possa
              selecioná-la para a seção SOBRE de seu site.
            </Alert>
          </AlertPane>
        ) : (
          <CheckImageGroup
            images={images}
            initialImages={aboutImages}
            size={100}
            onChange={handleSelectAboutImages}
          />
        )}

        <ButtonsPane>
          <ButtonNext>Salvar</ButtonNext>
        </ButtonsPane>
      </Form>

      <Dialog
        onClose={handleClickClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Selecionar logotipo</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Selecione abaixo a nova imagem desejada para definir como logotipo
            de seu site:
          </DialogContentText>
          <RadioImageGroup
            images={images}
            selectedId={selectedLogo}
            size={90}
            rounded
            onChange={handleChangeLogo}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={handleClickConfirm}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </PageStructure>
  );
};

export default About;
