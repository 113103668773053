import React, { useCallback, useRef } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Input';
import ButtonNext from '../../components/ButtonNext';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import TextArea from '../../components/TextArea';
import ButtonsPane from '../../components/ButtonsPane';

interface ContactFormData {
  businessAddress: string;
  contactText: string;
  whatsappNumber: string;
}

const ContactInfo: React.FC = () => {
  const { site, updateSite } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ContactFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          whatsappNumber: Yup.string().required(
            'É obrigatório inserir seu número de telefone com WhatsApp',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const payload = {
          id: site.id,
          businessAddress: data.businessAddress,
          contactText: data.contactText,
          whatsappNumber: data.whatsappNumber,
        };

        const response = await api.patch('/sites/contact', payload);

        updateSite(response.data);

        addToast({
          type: 'success',
          title: 'Informações de contato salvas com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao salvar as informações',
            description:
              'Tente novamente mais tarde. Se o erro persistir, contate o suporte.',
          });
        }
      }
    },
    [addToast, site.id, updateSite],
  );

  return (
    <PageStructure menuActive={5}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          contactText: site.contactText,
          whatsappNumber: site.whatsappNumber,
          businessAddress: site.businessAddress,
        }}
      >
        <h3>
          Aqui você poderá inserir suas informações de contato. Preencha os
          campos a seguir e clique em
          <strong> Salvar e prosseguir</strong>
        </h3>

        <TextArea
          rows={6}
          name="contactText"
          placeholder="Exemplo: Nosso atendimento funciona de segunda à sexta-feira, das 8 às 18 horas. Entre em contato pelo WhatsApp no botão abaixo ou através no e-mail contato@meusite.com.br."
        >
          Texto da área de contato. Você poderá inserir aqui um parágrafo com as
          informações que desejar
        </TextArea>

        <Input
          type="number"
          name="whatsappNumber"
          max={99999999999}
          maxLength={11}
          placeholder="Digite apenas números com DDD (Exemplo: 48988776655)"
        >
          Número de telefone com WhatsApp (comercial:)
        </Input>

        <TextArea
          rows={4}
          name="businessAddress"
          placeholder="Digite aqui o endereço"
        >
          Endereço comercial (opcional)
        </TextArea>

        <ButtonsPane>
          <ButtonNext>Salvar</ButtonNext>
        </ButtonsPane>
      </Form>
    </PageStructure>
  );
};

export default ContactInfo;
