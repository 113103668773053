import React from 'react';

import PageStructure from '../../components/PageStructure';

const SocialMedia: React.FC = () => {
  return (
    <PageStructure menuActive={1}>
      <h3>
        Em breve você poderá conectar seu site às suas contas comerciais no
        Facebook e Instagram. As informações postadas nas redes sociais poderão
        ser importadas facilmente para seu site, como descrição do negócio e
        fotografias postadas.
      </h3>
      <h3>
        Aguarde até a funcionalidade seja disponibilizada e use-a para
        enriquecer as informações em seu site.
      </h3>
    </PageStructure>
  );
};

export default SocialMedia;
