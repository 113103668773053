import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #e5e5e5;
  border-radius: 10px;
  padding: 0 16px;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;

  border: 1px solid #e5e5e5;
  color: #546e7a;

  ${props =>
    props.isErrored &&
    css`
      border-color: #e53935;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #388e3c;
      /* border-color: #388e3c; */
    `}


  ${props =>
    props.isFilled &&
    css`
      color: #388e3c;
    `}

  & + div {
    margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #546e7a;
    height: 100%;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #e5e5e5 inset !important;
      -webkit-text-fill-color: #546e7a;
    }

    &::placeholder {
      color: #90a4ae;
    }
  }

  > svg {
    margin-right: 16px;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #e53935;
    color: #fff;

    &::before {
      border-color: #e53935 transparent;
    }
  }
`;

export const Label = styled.p`
  font-weight: 300;
  font-size: 1.125rem;
  margin-bottom: 16px;
  margin-top: 32px;
`;
