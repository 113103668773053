import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Item = styled.div`
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 32px;
  border-bottom: 1px solid #e5e5e5;

  h2 {
    margin-top: 32px;
  }
`;

export const ButtonEdit = styled.button`
  background: #ff9000;
  margin-right: 10px;
  &:hover {
    background: ${shade(0.2, '#ff9000')};
  }
`;

export const ButtonDelete = styled.button`
  background: #e53935;
  &:hover {
    background: ${shade(0.2, '#e53935')};
  }
`;

export const ItemButtons = styled.div`
  right: 0;
  top: 32px;
  position: absolute;
  display: flex;
  button {
    width: 48px;
    height: 48px;

    border: 0;
    border-radius: 50%;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    svg {
      color: #fff;
      align-self: center;
    }
  }
`;

export const ItemImage = styled.div`
  width: 142px;
  height: 142px;
  display: flex;
  justify-content: center;

  button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    background: #ff9000;
    border: 0;
    border-radius: 50%;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    svg {
      color: #fff;
      align-self: center;
    }

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const ItemInfo = styled.div`
  h3 {
    margin-top: 32px !important;
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 1.125rem;
  }
  flex-grow: 1;
`;

export const ItemInfoRow = styled.div`
  display: flex;
`;

export const ItemInfoCol = styled.div`
  width: calc(50%);
  &:first-child {
    margin-right: 1rem;
  }
`;

export const ItemInfoColDescription = styled.div`
  width: calc(100% - 158px);
  margin-right: 1rem;
`;

export const ItemInfoColImage = styled.div`
  width: 120px;
`;

export const ItemImageContainer = styled.div`
  position: relative;
`;

export const AddButtonPane = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px;
`;

export const AddButton = styled.button`
  width: 48px;
  height: 48px;
  background: #ff9000;
  border: 0;
  border-radius: 50%;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    color: #fff;
    align-self: center;
  }

  &:hover {
    background: ${shade(0.2, '#ff9000')};
  }
`;

export const AlertPane = styled.div`
  margin-top: 16px;
`;
interface ItemImagePreviewProps {
  imageUrl: string;
}

export const ItemImagePreview = styled.div<ItemImagePreviewProps>`
  border-radius: 10px;
  /* color: #90a4ae; */
  display: flex;
  align-items: center;
  width: 142px;
  height: 142px;
  justify-content: center;
  align-self: center;
  border: 1px solid #e5e5e5;

  /* border: 1px solid #e5e5e5; */

  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${props => {
    return css`
      background-image: url(${props.imageUrl});
    `;
  }}
`;
