import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCamera, FiEdit, FiPlus, FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ButtonNext from '../../components/ButtonNext';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import ButtonsPane from '../../components/ButtonsPane';
import {
  AddButton,
  AddButtonPane,
  ButtonDelete,
  ButtonEdit,
  Item,
  ItemButtons,
  ItemImage,
  ItemImageContainer,
  ItemImagePreview,
  ItemInfo,
  ItemInfoCol,
  ItemInfoColDescription,
  ItemInfoColImage,
  ItemInfoRow,
} from './styles';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import noImage from '../../assets/no-image.png';
import RadioImageGroup from '../../components/RadioImageGroup';
import ButtonCancel from '../../components/ButtonCancel';
import { AlertPane } from '../About/styles';

interface ItemFormData {
  title: string;
  description: string;
  pricing: string;
}
interface Image {
  id: string;
  url: string;
}

interface Item {
  id: string;
  title: string;
  description: string;
  pricing: string;
  imageId: string;
  created_at: Date;
}

const Items: React.FC = () => {
  const { site } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRef2 = useRef<FormHandles>(null);
  const [selectedImageTemp, setSelectedImageTemp] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [images, setImages] = useState<Image[]>([]);
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    api.get(`images/site/${site.id}`).then(response => {
      setImages(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedImage('');
    if (newItem && selectedItemToEdit) setSelectedItemToEdit('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem]);

  useEffect(() => {
    if (selectedItemToEdit && newItem) setNewItem(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemToEdit]);

  const { addToast } = useToast();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const updateItems = useCallback(() => {
    api.get(`items/${site.id}`).then(response => {
      setItems(response.data);
    });
  }, [site.id]);

  const handleSubmit = useCallback(
    async (data: ItemFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required(
            'É obrigatório inserir um título para o produto/serviço',
          ),
          pricing: Yup.string().required(
            'Digite o valor do produto/serviço. Caso o valor seja variável, digite algo como "A partir de..." ou "Consulte valores".',
          ),
          description: Yup.string().required(
            'Insira aqui os detalhes sobre o seu produto ou serviço. Quanto mais detalhado, melhor será seua visibilidade.',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const { title, description, pricing } = data;
        const payload = {
          id: selectedItemToEdit,
          title,
          description,
          pricing,
          imageId: selectedImage,
        };

        await api.put('/items', payload);

        addToast({
          type: 'success',
          title: 'Produto/serviço alterado com sucesso',
        });

        setSelectedItemToEdit('');
        setSelectedImage('');

        updateItems();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao alterar produto/serviço',
            description:
              'Tente novamente mais tarde. Se o erro persistir, contate o suporte.',
          });
        }
      }
    },
    [addToast, selectedItemToEdit, selectedImage, updateItems],
  );

  const handleSubmitNewItem = useCallback(
    async (data: ItemFormData) => {
      try {
        formRef2.current?.setErrors({});

        const schema2 = Yup.object().shape({
          title: Yup.string().required(
            'É obrigatório inserir um título para o produto/serviço',
          ),
          pricing: Yup.string().required(
            'Digite o valor do produto/serviço. Caso o valor seja variável, digite algo como "A partir de..." ou "Consulte valores".',
          ),
          description: Yup.string().required(
            'Insira aqui os detalhes sobre o seu produto ou serviço. Quanto mais detalhado, melhor será seua visibilidade.',
          ),
        });

        await schema2.validate(data, {
          abortEarly: false,
        });

        formRef2.current?.setErrors({});

        const { title, description, pricing } = data;
        const payload = {
          siteId: site.id,
          title,
          description,
          pricing,
          imageId: selectedImage,
          itemType: site.itemsType === 'products' ? 'product' : 'service',
        };

        await api.post('/items', payload);

        addToast({
          type: 'success',
          title: 'Produto/serviço criado com sucesso',
        });

        setNewItem(false);
        setSelectedImage('');

        updateItems();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef2.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao salvar as informações',
            description:
              'Tente novamente mais tarde . Se o erro persistir, contate o suporte.',
          });
        }
      }
    },
    [addToast, selectedImage, updateItems, site.id, site.itemsType],
  );

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');

  const handleClickConfirm = () => {
    setOpen(false);
    setSelectedImage(selectedImageTemp);
  };

  const handleChangeImage = useCallback(selectedImageId => {
    setSelectedImageTemp(selectedImageId);
  }, []);

  const handleClickCloseConfirmDelete = () => {
    setItemToDelete('');
    setConfirmDeleteOpen(false);
  };

  const handleClickConfirmDelete = async () => {
    try {
      await api.delete(`/items/${itemToDelete}`);

      addToast({
        type: 'success',
        title: 'Item excluído com sucesso!',
      });
      setConfirmDeleteOpen(false);
      setItemToDelete('');
      updateItems();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir item',
        description:
          'Tente novamente mais tarde . Se o erro persistir, contate o suporte.',
      });
    }
  };

  return (
    <PageStructure menuActive={4}>
      <h3>
        Gerencie aqui seus produtos ou serviços disponíveis em seu site. Você
        poderá criar até 10 items. Edite ou exclua-os sempre que necessitar.
      </h3>
      <h3>
        Cada item à venda deverá ter um título (obrigatório) e opcionalmente um
        parágrafo de descrição, uma imagem e seu preço. Em seu site, cada item
        contará com um botão Solicitar, o qual, quando clicado por um visitante
        em seu site, abrirá uma conversa com seu número de WhatsApp onde você
        poderá combinar com o cliente os trâmites da compra ou contratação do
        serviço.
      </h3>

      {items.length === 0 && !newItem && (
        <AlertPane>
          <Alert severity="info">
            Clique no botão abaixo e crie seu primeiro produto/serviço.
          </Alert>
        </AlertPane>
      )}

      {items
        .sort((itemA, itemB) => {
          return itemA.created_at < itemB.created_at ? -1 : 1;
        })
        .map(item => {
          return item.id === selectedItemToEdit ? (
            <Form
              key={item.id}
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                title: item.title,
                pricing: item.pricing,
                description: item.description,
                imageId: item.imageId,
              }}
            >
              <Item>
                <ItemInfo>
                  <ItemInfoRow>
                    <ItemInfoCol>
                      <Input
                        name="title"
                        placeholder="Ex: Faca de churrasco customizada"
                      >
                        Nome do produto/serviço
                      </Input>
                    </ItemInfoCol>
                    <ItemInfoCol>
                      <Input
                        name="pricing"
                        placeholder="Ex: R$ 1.129,90 / Consulte"
                      >
                        Preço
                      </Input>
                    </ItemInfoCol>
                  </ItemInfoRow>
                  <ItemInfoRow>
                    <ItemInfoColDescription>
                      <TextArea
                        rows={5}
                        name="description"
                        placeholder="Exemplo: Faca de churrasco customizada artesanalmente
              feita em aço damasco. Possui comprimento de 30 cm, cabo em madeira
              de lei e osso de gnu do Canadá, com anéis em cobre. Acompanha baínha
              em couro bovino com passador para cinto e presilha para cintura."
                      >
                        Descrição
                      </TextArea>
                    </ItemInfoColDescription>
                    <ItemInfoColImage>
                      <h3>Imagem</h3>
                      <ItemImage>
                        <ItemImageContainer>
                          <ItemImagePreview
                            imageUrl={
                              images.find(image => image.id === selectedImage)
                                ?.url || noImage
                            }
                          />
                          <button
                            type="button"
                            title="Selecionar outra imagem"
                            onClick={handleClickOpen}
                          >
                            <FiCamera />
                          </button>
                        </ItemImageContainer>
                      </ItemImage>
                    </ItemInfoColImage>
                  </ItemInfoRow>
                </ItemInfo>
                <ButtonsPane>
                  <ButtonCancel
                    type="button"
                    tabIndex={-1}
                    onClick={() => setSelectedItemToEdit('')}
                  >
                    Cancelar
                  </ButtonCancel>
                  <ButtonNext>Salvar</ButtonNext>
                </ButtonsPane>
              </Item>
            </Form>
          ) : (
            <Item key={item.id}>
              <ItemButtons>
                <ButtonEdit
                  type="button"
                  onClick={() => {
                    setSelectedItemToEdit(item.id);
                    setSelectedImage(item.imageId);
                  }}
                >
                  <FiEdit />
                </ButtonEdit>
                <ButtonDelete
                  type="button"
                  onClick={() => {
                    setItemToDelete(item.id);
                    setConfirmDeleteOpen(true);
                  }}
                >
                  <FiX />
                </ButtonDelete>
              </ItemButtons>
              <ItemInfo>
                <ItemInfoRow>
                  <ItemInfoCol>
                    <h3>Nome do produto/serviço</h3>
                    <p>{item.title}</p>
                  </ItemInfoCol>
                  <ItemInfoCol>
                    <h3>Preço</h3>
                    <p>{item.pricing}</p>
                  </ItemInfoCol>
                </ItemInfoRow>
                <ItemInfoRow>
                  <ItemInfoColDescription>
                    <h3>Descrição</h3>
                    <p>{item.description}</p>
                  </ItemInfoColDescription>
                  <ItemInfoColImage>
                    <h3>Imagem</h3>
                    <ItemImage>
                      <ItemImageContainer>
                        <ItemImagePreview
                          imageUrl={
                            item.imageId
                              ? images.find(image => image.id === item.imageId)
                                  ?.url ?? noImage
                              : noImage
                          }
                        />
                      </ItemImageContainer>
                    </ItemImage>
                  </ItemInfoColImage>
                </ItemInfoRow>
              </ItemInfo>
            </Item>
          );
        })}

      {!newItem && (
        <AddButtonPane>
          <AddButton
            title="Criar novo produto/serviço"
            onClick={() => setNewItem(true)}
          >
            <FiPlus />
          </AddButton>
        </AddButtonPane>
      )}

      {newItem && (
        <Form ref={formRef2} onSubmit={handleSubmitNewItem} initialData={{}}>
          <Item>
            <h2>Novo produto/serviço</h2>
            <ItemInfo>
              <ItemInfoRow>
                <ItemInfoCol>
                  <Input
                    name="title"
                    placeholder="Ex: Faca de churrasco customizada"
                  >
                    Nome do produto/serviço
                  </Input>
                </ItemInfoCol>
                <ItemInfoCol>
                  <Input
                    name="pricing"
                    placeholder="Ex: R$ 1.129,90 / Consulte"
                  >
                    Preço
                  </Input>
                </ItemInfoCol>
              </ItemInfoRow>
              <ItemInfoRow>
                <ItemInfoColDescription>
                  <TextArea
                    rows={5}
                    name="description"
                    placeholder="Exemplo: Faca de churrasco customizada artesanalmente
              feita em aço damasco. Possui comprimento de 30 cm, cabo em madeira
              de lei e osso de gnu do Canadá, com anéis em cobre. Acompanha baínha
              em couro bovino com passador para cinto e presilha para cintura."
                  >
                    Descrição
                  </TextArea>
                </ItemInfoColDescription>
                <ItemInfoColImage>
                  <h3>Imagem</h3>
                  <ItemImage>
                    <ItemImageContainer>
                      <ItemImagePreview
                        imageUrl={
                          images.find(image => image.id === selectedImage)
                            ?.url || noImage
                        }
                      />
                      <button
                        type="button"
                        title="Selecionar outra imagem"
                        onClick={handleClickOpen}
                      >
                        <FiCamera />
                      </button>
                    </ItemImageContainer>
                  </ItemImage>
                </ItemInfoColImage>
              </ItemInfoRow>
            </ItemInfo>
            <ButtonsPane>
              <ButtonCancel
                type="button"
                tabIndex={-1}
                onClick={() => setNewItem(false)}
              >
                Cancelar
              </ButtonCancel>
              <ButtonNext>Salvar</ButtonNext>
            </ButtonsPane>
          </Item>
        </Form>
      )}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Selecionar imagem</DialogTitle>
        <DialogContent dividers>
          {images.length > 0 ? (
            <>
              <DialogContentText>
                Selecione abaixo a imagem desejada para seu produto/serviço:
              </DialogContentText>
              <RadioImageGroup
                images={images}
                // selectedId={selectedLogo}
                size={90}
                onChange={handleChangeImage}
              />
            </>
          ) : (
            <Alert severity="warning">
              Você ainda não possui nenhuma imagem em sua galeria. Vá até a
              galeria no menu principal e faça a importação de alguma imagem
              para que possa selecioná-la como imagem de um de seu
              produto/serviço.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant="contained">
            Cancelar
          </Button>
          {images.length > 0 && (
            <Button
              onClick={handleClickConfirm}
              color="primary"
              variant="contained"
              autoFocus
            >
              Confirmar
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDeleteOpen}
        onClose={handleClickCloseConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar remoção de produto/serviço
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este produto/serviço será permanentemente removido. Deseja
            prosseguir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseConfirmDelete} variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={handleClickConfirmDelete}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </PageStructure>
  );
};

export default Items;
