import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useToast } from '../../hooks/toast';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { ImageContainer, ImagesList, Image, ButtonContainer } from './styles';

interface Image {
  id: string;
  url: string;
}

const Images: React.FC = () => {
  const [confirmDeleteopen, setConfirmDeleteOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState('');
  const { site } = useAuth();

  const { addToast } = useToast();

  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    api.get(`images/site/${site.id}`).then(response => {
      setImages(response.data);
    });
  }, []);

  const handleClickOpenConfirmDelete = (imageId: string) => {
    setImageToDelete(imageId);
    setConfirmDeleteOpen(true);
  };

  const handleClickCloseConfirmDelete = () => {
    setImageToDelete('');
    setConfirmDeleteOpen(false);
  };

  const handleClickConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    api.delete(`images/${imageToDelete}`).then(() => {
      const newImages = images.filter(image => image.id !== imageToDelete);
      setImages(newImages);
      addToast({
        type: 'success',
        title: 'Imagem removida com sucesso!',
      });
    });
    setImageToDelete('');
  };

  const handleNewImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();
        data.append('image', e.target.files[0]);
        data.append('siteId', site.id);
        data.append('origin', 'uploaded');
        api
          .post('/images', data)
          .then(response => {
            const image = response.data as Image;
            addToast({
              title: 'Imagem enviada com sucesso!',
              type: 'success',
            });
            setImages([...images, image]);
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Erro ao realizar upload',
              description:
                'Tente novamente mais tarde. Se o erro persistir, contate o suporte.',
            });
          });
      }
    },
    [addToast, site.id, images],
  );

  return (
    <PageStructure menuActive={2}>
      <h3>
        Aqui você poderá gerenciar as imagens de seu site. Visualize, faça
        upload, importe de suas redes sociais ou exclua as imagens disponíveis
        para seu site.
      </h3>
      <h3>Todas as imagens:</h3>
      <ImagesList>
        {images.map(image => {
          return (
            <ImageContainer key={image.id}>
              <Image imageUrl={image.url} />
              <button
                type="button"
                title="Excluir imagem"
                onClick={() => {
                  handleClickOpenConfirmDelete(image.id);
                }}
              >
                <FiX />
              </button>
            </ImageContainer>
          );
        })}
        <ButtonContainer>
          <label htmlFor="newImage" title="Fazer upload de nova imagem">
            <FiPlus />
            <input type="file" id="newImage" onChange={handleNewImage} />
          </label>
        </ButtonContainer>
      </ImagesList>
      <Dialog
        open={confirmDeleteopen}
        onClose={handleClickCloseConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmar remoção imagem?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao remover essa imagem ela não estará mais disponível para uso e
            será removida de todos os locais onde aos quais está relacionada,
            como logotipo do site, imagem de produtos ou serviços ou ainda na
            área SOBRE de seu site.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseConfirmDelete} variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={handleClickConfirmDelete}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </PageStructure>
  );
};

export default Images;
