import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const AlertPane = styled.div`
  margin-top: 16px;
`;

export const LogoContainer = styled.div`
  width: 202px;
  height: 202px;
  position: relative;
`;

export const LogoPreview = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;

  button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 64px;
    height: 64px;
    background: #ff9000;
    border: 0;
    border-radius: 50%;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    svg {
      color: #fff;
      align-self: center;
    }

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;

interface LogoProps {
  imageUrl: string;
}

export const Logo = styled.div<LogoProps>`
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  color: #90a4ae;
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-self: center;

  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${props => {
    return css`
      background-image: url(${props.imageUrl});
    `;
  }}
`;
