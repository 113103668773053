import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
`;

export const Header = styled.header`
  padding: 16px;
  background: #636e72;
  /* #f8f9fa!important */
`;

export const HeaderContent = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  > div {
    width: 300px;
    display: flex;
    justify-content: center;
    > img {
      height: 80px;
    }
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;
    width: 48px;
    height: 48px;
    transition: 0.2s;
    border-radius: 50%;

    svg {
      color: #fff;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  div + div {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    line-height: 24px;

    span {
      color: #fff;
    }

    strong {
      color: #ff9000;
      font-weight: bold;
    }
  }
`;

export const Content = styled.section`
  max-width: 1360px;
  margin: 64px auto;
  padding: 0 16px;
  display: flex;
`;

export const ContentForm = styled.div`
  flex: 1;
  margin-left: 32px;

  h3 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 32px;
  }
`;
