import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 16px;
  }
`;
