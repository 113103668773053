import React from 'react';
import {
  FiBookOpen,
  FiImage,
  FiMail,
  FiMonitor,
  FiPackage,
  FiShoppingCart,
  FiThumbsUp,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface MenuProps {
  menuActive?: number;
}

const Menu: React.FC<MenuProps> = ({ menuActive, ...props }) => {
  return (
    <Container>
      <ul>
        <li className={menuActive === 0 ? 'active' : ''}>
          <Link to="/informacoes-basicas">
            <FiBookOpen />
            Informações básicas
          </Link>
        </li>
        <li className={menuActive === 1 ? 'active' : ''}>
          <Link to="/redes-sociais">
            <FiThumbsUp />
            Redes sociais
          </Link>
        </li>
        <li className={menuActive === 2 ? 'active' : ''}>
          <Link to="/imagens">
            <FiImage />
            Galeria de Imagens
          </Link>
        </li>
        <li className={menuActive === 3 ? 'active' : ''}>
          <Link to="/sobre">
            <FiPackage />
            Sobre o negócio
          </Link>
        </li>
        <li className={menuActive === 4 ? 'active' : ''}>
          <Link to="/items">
            <FiShoppingCart />
            Produtos / Serviços
          </Link>
        </li>
        <li className={menuActive === 5 ? 'active' : ''}>
          <Link to="/contato">
            <FiMail />
            Informações de contato
          </Link>
        </li>
        <li className={menuActive === 6 ? 'active' : ''}>
          <Link to="/publicacao">
            <FiMonitor />
            Publicação do site
          </Link>
        </li>
      </ul>
    </Container>
  );
};

export default Menu;
