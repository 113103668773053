import styled, { css } from 'styled-components';

interface ContainerProps {
  size: number;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-gap: 20px;
  margin-top: 16px;
  ${imageProps =>
    css`
      grid-template-columns: repeat(auto-fit, ${imageProps.size}px);
    `}
  justify-content: start;
`;

export const RadioImage = styled.label`
  input {
    display: none;
  }
`;

interface ImageProps {
  imageUrl: string;
  size: number;
  checked: boolean;
  rounded: boolean;
}

export const Image = styled.div<ImageProps>`
  border: 1px solid #eee;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  ${imageProps => {
    return css`
      background-image: url(${imageProps.imageUrl});
      height: ${imageProps.size}px;
      width: ${imageProps.size}px;
    `;
  }}

  ${imageProps =>
    imageProps.checked &&
    css`
      opacity: 0.7;
      border: 5px solid #ff9000;
    `}

  ${imageProps =>
    imageProps.rounded &&
    css`
      border-radius: 50%;
    `}

  svg {
    transition: 0.2s;
    color: #ff9000;
    ${imageProps => {
      return css`
        height: ${imageProps.size / 1.5}px;
        width: ${imageProps.size / 1.5}px;
      `;
    }}
  }
`;
