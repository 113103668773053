import React, { useEffect, useState } from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container, RadioImage, Image } from './styles';

interface Image {
  id: string;
  url: string;
  imageId: string;
  imageType: 'about' | 'logo' | 'banner';
}

interface CheckImageGroupProps {
  images: Image[];
  size?: number;
  rounded?: boolean;
  onChange(selectedImageIds: string[]): void;
  initialImages: Image[];
}

const CheckImageGroup: React.FC<CheckImageGroupProps> = ({
  images,
  size,
  rounded,
  onChange,
  initialImages,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>([]);

  useEffect(() => {
    const array = initialImages.map(image => image.imageId);
    // console.log(array);
    setSelectedCheckbox(array);
  }, [initialImages]);

  useEffect(() => {
    onChange(selectedCheckbox);
  }, [selectedCheckbox, onChange]);

  return (
    <Container size={size ?? 128}>
      {images.map(image => {
        return (
          <RadioImage htmlFor={image.id} key={image.id}>
            <Image
              imageUrl={image.url}
              size={size ?? 128}
              checked={selectedCheckbox.includes(image.id)}
              rounded={rounded ?? false}
            >
              {selectedCheckbox.includes(image.id) && <FiCheck />}
            </Image>
            <input
              id={image.id}
              type="checkbox"
              value={image.id}
              name="group1"
              checked={selectedCheckbox.includes(image.id)}
              onChange={element => {
                if (!element.target.checked) {
                  const newSelected = selectedCheckbox.filter(
                    e => e !== image.id,
                  );
                  // console.log(newSelected);
                  setSelectedCheckbox(newSelected);
                } else {
                  setSelectedCheckbox([...selectedCheckbox, image.id]);
                }
              }}
            />
          </RadioImage>
        );
      })}
    </Container>
  );
};

export default CheckImageGroup;
