import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  height: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;

  p {
    font-size: 14px;
    text-align: center;
    color: #888;
  }
`;
