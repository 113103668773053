import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PublishedStatus = styled.div`
  color: #27ae60;
  background: #edfbf3;
`;

export const UnpublishedStatus = styled.div`
  color: #e53935;
  background: #fcebea;
`;

export const PublishedPanel = styled.div`
  display: flex;
  margin-top: 10px;
  > div {
    padding: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;

    width: 200px;
    border-radius: 10px;
    justify-content: center;
    svg {
      margin-right: 6px;
    }
  }
`;

export const UnpublishButton = styled.button`
  padding: 16px;
  margin-left: 16px;
  border: 0;
  border-radius: 10px;
  width: 200px;
  background: #e53935;
  color: #fff;
  transition: 0.2s;
  letter-spacing: 1px;

  &:hover {
    background: ${shade(0.2, '#e53935')};
  }
`;

export const PublishButton = styled.button`
  padding: 16px;
  margin-left: 16px;
  border: 0;
  border-radius: 10px;
  width: 200px;
  background: #27ae60;
  color: #fff;
  transition: 0.2s;
  letter-spacing: 1px;

  &:hover {
    background: ${shade(0.2, '#27ae60')};
  }
`;
