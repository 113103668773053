import styled from 'styled-components';

export const Container = styled.nav`
  width: 340px;
  color: #888;
  height: 100%;

  ul {
    font-size: 18px;
    width: 100%;
    li {
      height: 60px;

      display: flex;
      align-items: center;
      transition: background 0.2s;
      &:hover {
        background: #f5f5f5;
      }

      & + li {
        border-top: 1px solid #eee;
      }
      &.active {
        background: #fafafa;
        color: #ff9000;
        a {
          cursor: default;
          pointer-events: none;
        }
        &::before {
          content: '';
          width: 2px;
          background: #ff9000;
          height: 100%;
        }
      }

      svg {
        margin-right: 16px;
      }

      /* &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      } */
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        padding: 0 32px;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
