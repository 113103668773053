import React, { useCallback, useRef } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import PageStructure from '../../components/PageStructure';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import {
  PublishButton,
  PublishedPanel,
  PublishedStatus,
  UnpublishButton,
  UnpublishedStatus,
} from './styles';

const Publish: React.FC = () => {
  const { site, updateSite } = useAuth();

  const { addToast } = useToast();

  const handlePublish = async () => {
    try {
      const payload = {
        id: site.id,
        publish: true,
      };
      const response = await api.patch(`/sites/publish`, payload);

      addToast({
        type: 'success',
        title: 'Site publicado com sucesso!',
        description: 'Você já pode acessar seu site.',
      });

      updateSite(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao publicar o site',
        description:
          'Tente novamente mais tarde. Se o erro persistir, contate o suporte.',
      });
    }
  };

  const handleUnpublish = async () => {
    try {
      const payload = {
        id: site.id,
        publish: false,
      };
      const response = await api.patch(`/sites/publish`, payload);

      addToast({
        type: 'info',
        title: 'Site despublicado com sucesso!',
        description: 'Seu site não está mais disponível para acesso.',
      });

      updateSite(response.data);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao depublicar o site',
        description:
          'Tente novamente mais tarde. Se o erro persistir, contate o suporte.',
      });
    }
  };

  return (
    <PageStructure menuActive={6}>
      <h3>
        {`Gerencie aqui o estado de seu site. Depois de preencher todos os passos
        anteriores, você poderá publicar seu site. A partir de então, o mesmo
        poderá ser acesso em seu domínio `}
        <strong>
          www.
          {site.domain}
        </strong>
      </h3>
      <h3>
        Estado atual do site:
        <div>
          {site.published ? (
            <PublishedPanel>
              <PublishedStatus>
                <FiEye />
                Publicado
              </PublishedStatus>
              <UnpublishButton onClick={handleUnpublish} type="button">
                Despublicar
              </UnpublishButton>
            </PublishedPanel>
          ) : (
            <div>
              <PublishedPanel>
                <UnpublishedStatus>
                  <FiEyeOff />
                  Não publicado
                </UnpublishedStatus>
                <PublishButton onClick={handlePublish} type="button">
                  Publicar
                </PublishButton>
              </PublishedPanel>
            </div>
          )}
        </div>
      </h3>
    </PageStructure>
  );
};

export default Publish;
