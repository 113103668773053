import styled, { css } from 'styled-components';

export const Container = styled.div``;

interface RadioProps {
  checked: boolean;
}
export const Radio = styled.label<RadioProps>`
  &:first-child div {
    border-radius: 10px 0 0 10px;
  }

  &:last-child div {
    border-radius: 0 10px 10px 0;
  }

  div {
    background: #e5e5e5;
    &:hover {
      background: #efefef;
    }
    cursor: pointer;
    ${props =>
      props.checked &&
      css`
        background: #ff9000;
        color: #fff;
        font-weight: 600;
        cursor: default;
        pointer-events: none;
      `}
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 150px;

    border-right: 0;
    transition: 0.2s;
  }

  input {
    display: none;
  }
`;

export const RadioGroup = styled.div`
  margin-top: 16px;
  display: flex;
`;
